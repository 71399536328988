import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin-left: ${props => props.theme.spacing * 4}px;
  margin-right: ${props => props.theme.spacing * 4}px;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    margin-top: ${props => props.theme.spacing * 4}px;
    margin-left: ${props => props.theme.spacing * 8}px;
    margin-right: ${props => props.theme.spacing * 4}px;
  }
`

export default Container;