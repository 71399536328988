import React from "react"
import Layout from '../../components/layout'
import Section from '../../components/section'
import Banner from '../../components/banner'
import Container from '../../components/container'
import Typography from '../../components/typography'
import Seo from '../../components/seo';

export default function ProjectManagement(props) {
  return (
    <Layout {...props}>
      <Seo 
        title="Project Management" 
        description="Ensure proper coordination with every trade contractor with regards to the on-going construction and assuring all actions are according to approved plans and coordinated with the client's approval." 
      />
      <Section>
        <Banner 
          src="/img/sample-hero-image-1.jpg"
          header="Project Management"
        />
      </Section>
      <Section>
        <Container>
          <Typography component="p">
            Ensure proper coordination with every trade contractor with regards to the on-going construction and assuring all
            actions are according to approved plans and coordinated with the client's approval.
          </Typography>
        </Container>
      </Section>
    </Layout>
  )
}
