import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: auto;
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${props => props.imgSrc});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    margin-left: ${props => props.theme.spacing * 8}px;
    margin-right: ${props => props.theme.spacing * 4}px;
  }
`

const Header = styled.h1`
  font-size: 3rem;
  color: ${props => props.theme.palette.primary.main};
  text-align: center;
`

const Banner = (props) => {
  const {
    src,
    header,
    children,
    ...otherProps
  } = props

  return (
    <Container imgSrc={src} {...otherProps}>
      <Header>{header}</Header>
      { children }
    </Container>
  )
}

export default Banner