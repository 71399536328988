import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const P = styled.p`
  font-size: 1.25rem;
  color: ${props => props.theme.palette.primary.disabled};
`

const Span = styled.span`
  font-size: 1.25rem;
  color: ${props => props.theme.palette.primary.contrast};
`
const B = styled.b`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${props => props.theme.palette.primary.contrast};
`

const H2 = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  color: ${props => props.theme.palette.primary.contrast};
`

const components = {
  'p': P,
  'span': Span,
  'b': B,
  'h2': H2
}

const Typography = (props) => {
  const {
    component,
    children,
    ...otherProps
  } = props 

  const TextComponent = components[component]


  return (
    <TextComponent {...otherProps}>
      { children }
    </TextComponent>
  )
}

Typography.propTypes = {
  component: PropTypes.oneOf([
    'p',
    'span',
    'b',
    'h2',
  ])
}

export default Typography